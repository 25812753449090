<template>
    <FormTemplate v-if="form" :form="form">
      <b-form-group class="label-for-xl" label="Password" label-for="inputPassword">
        <b-input-group>
          <b-form-input
              type="text" id="inputPassword" class="form-control form-control-xl"
              placeholder="set password"
              v-model="form.data.password"
              :state="form.states.password"
          />
          <b-input-group-append>
            <b-button variant="primary" @click="generatePassword()"><BIconLock/></b-button>
          </b-input-group-append>
          <b-form-invalid-feedback v-if="form.errors">{{ form.errors.password }}</b-form-invalid-feedback>
        </b-input-group>
      </b-form-group>
    </FormTemplate>
</template>

<script>
import {BIconLock} from 'bootstrap-vue';
import {Form} from '@/common/utils/parnekt';
import {randomString} from '@/common/utils/common';
import FormTemplate from '@/common/components/Form/Form';

export default {
    props: ['userDetails'],
    components: {
        FormTemplate,
        BIconLock
    },
    data(){
        return {
            form: null
        };
    },

    methods: {
      generatePassword(){
        this.form.set('password', randomString(10));
      }
    },

    mounted(){
      this.form = new Form({
        password: ""
      },{
        onSubmit: (form) => {
          return this.$api.put(`user/${this.userDetails.id}/password`,{
            data: {
              password: form.get('password'),
            }
          });
        },
        onSuccess: (form) => {
            form.resetData();
        }
      });
    }
};
</script>
