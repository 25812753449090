<template>
    <FormTemplate v-if="form" :form="form">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputFirstName">First Name</label>
                    <b-form-input
                        type="text" id="inputFirstName" class="form-control form-control-xl"
                        placeholder="enter first name"
                        v-model="form.data.firstName"
                        :state="form.states.firstName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.firstName }}</b-form-invalid-feedback>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputLastName">Last Name</label>
                    <b-form-input
                        type="text" id="inputLastName" class="form-control form-control-xl"
                        placeholder="enter last name"
                        v-model="form.data.lastName"
                        :state="form.states.lastName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.lastName }}</b-form-invalid-feedback>
                </div>
            </div>
        </div>
        <b-form-group class="label-for-xl" label="E-Mail Address" label-for="inputEmail">
            <b-form-input
                type="text" id="inputEmail" class="form-control form-control-xl"
                placeholder="enter the email address"
                v-model="form.data.email"
                :state="form.states.email"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.email }}</b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col col lg="6" md="6" sm="12">
            <b-form-group class="label-for-xl" label="Role">
              <b-form-select v-model="form.data.role" :state="form.states.role" :options="roles"></b-form-select>
              <b-form-invalid-feedback v-if="form.errors">{{ form.errors.role }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col col lg="6" md="6" sm="12">
            <b-form-group class="label-for-xl" label="Language">
              <b-form-select v-model="form.data.locale" :state="form.states.locale" :options="languages"></b-form-select>
              <b-form-invalid-feedback v-if="form.errors">{{ form.errors.locale }}</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    props: ['parentUser'],
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null,
            roles: [],
            languages: [
              {
                value: "en",
                text: "English"
              },
              {
                value: "de",
                text: "Deutsch"
              },
            ]
        };
    },

    methods: {

    },

    mounted(){
      this.$api.get('roles').then(({data}) => {
        this.roles = [
          {
            text: "--- choose role ---",
            value: ""
          }
        ].concat(data.records.map(item => {
          return {
            value: item.id,
            text: item.name
          }
        }));

        this.form = new Form({firstName: "",lastName: "",email: "", role: "", locale: "de"},{
          onSubmit: (form) => {
            let data = {
              firstName: form.get('firstName'),
              lastName: form.get('lastName'),
              email: form.get('email'),
              role: form.get('role'),
              locale: form.get('locale')
            };
            if(this.parentUser){
              data.parentId = this.parentUser.id;
            }

            return this.$api.post('user',{
              data: data
            }).then((res) => {
              this.$emit('user-created',res.data);
              return res;
            });
          },
          onSuccess: (form) => {
            form.resetData();
          }
        });
      })

    }
};
</script>
