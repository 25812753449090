<template>
  <div>
    <div v-if="!selectedChannel">
      <div v-if="!table.isLoading">
        <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
          <template #empty>
            no records
          </template>

          <template #cell(origins)="data">
            <template v-if="data.item.origins">
              <template v-if="data.item.origins.length > 0">
                <div v-for="(item,key) in data.item.origins" :key="key">{{item.domain}}</div>
              </template>
              <div v-else class="text-muted">no origins defined</div>
            </template>
            <div v-else class="text-muted">no origins defined</div>
          </template>

          <template #cell(options)="data">
            <div class="text-right">
              <b-button variant="link" v-b-tooltip.hover title="Edit" size="sm" @click="setSelectedChannel(data.item)"><BIconPencil/></b-button>
            </div>
          </template>
        </b-table>
        <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
      </div>
      <b-spinner v-else label="Loading..." small></b-spinner>
    </div>

    <div v-if="selectedChannel">
      <div class="mb-3 p-3 border-bottom border-top bg-light">
        <b-row>
          <b-col>
            <h6><strong>{{selectedChannel.name || selectedChannel.id}}</strong></h6>
          </b-col>
          <b-col class="text-right">
            <b-button variant="success" size="sm" class="mr-2" @click="saveChannel()">Save</b-button>
            <b-button variant="primary" size="sm" @click="selectedChannel = null">Cancel</b-button>
          </b-col>
        </b-row>
      </div>
      <b-button size="sm" text="add" variant="primary" class="mb-3" @click="newOrigin()">new origin <BIconPlus/></b-button>
      <b-input-group class="mb-1" v-for="(item, key) in selectedChannel.origins" :key="key">
        <b-form-input v-model="item.domain" placeholder="enter origin"></b-form-input>
        <b-input-group-append>
          <b-button size="sm" text="remove" variant="danger" @click="removeOrigin(item,key)"><BIconX/></b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
  </div>
</template>

<script>
import {BIconPencil,BIconX,BIconPlus} from 'bootstrap-vue';
import moment from 'moment';
export default {
    props: ['userDetails'],
    components: {
      BIconPencil,BIconX,BIconPlus
    },
    data(){
        return {
          selectedChannel: null,
          table: {
            isLoading: false,
            records: [],
            pagination: {
              currentPageNo: 1
            },
            fields: [
              {
                key: 'createdAt',
                label: "Created",
                formatter: (value) => {
                  return moment(value).format('DD/MM/YYYY');
                }
              },
              {
                key: 'name',
                label: "Name"
              },
              {
                key: 'origins',
                label: "Origins",
              },
              {
                key: 'options',
                label: ""
              },
            ]
          }
        };
    },

    methods: {
      newOrigin(){
        this.selectedChannel.origins.push({domain: ""});
      },
      removeOrigin(item,key){
        this.selectedChannel.origins = this.selectedChannel.origins.filter((o,k) => k !== key);
      },
      setSelectedChannel(item){
        this.selectedChannel = Object.assign({},item);
      },
      saveChannel(){
        this.$api.post(`rtc/channels/origins/${this.userDetails.id}/${this.selectedChannel.id}`,{
          data: {
            origins: this.selectedChannel.origins
          }
        }).then(() => {
          this.selectedChannel = null;
          this.getChannels();
        })
        .catch(e => {
          this.selectedChannel = null;
          this.getChannels();
          console.log("cannot save channel", e);
        })

      },
      getChannels(){
        this.table.isLoading = true;
        this.$api.get(`rtc/channels/${this.userDetails.id}`,{
          limit: 100
        }).then(({data}) => {
          this.table.isLoading = false;
          this.table.records = data.records;
          this.table.pagination = data.pagination;
        })
      }
    },

    mounted(){
      this.getChannels()
    }
};
</script>
