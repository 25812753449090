<template>
  <div v-if="!table.isLoading">
    <b-table class="bg-white" responsive hover :items="table.records" :fields="table.fields" show-empty>
      <template #empty>
        no records
      </template>
      <template #cell(name)="data">
        <b-link v-if="getName(data.item)" :to='{name: "users.user.details", params: {userId: data.item.id}}'>
          {{getName(data.item)}}
        </b-link>
        <span v-else><i class="small">undefined</i></span>
      </template>
      <template #cell(options)="data">
        <div class="text-right">
          <b-link v-b-tooltip.hover title="Edit" :to='{name: "users.user.details", params: {userId: data.item.id}}'><BIconPencil/></b-link>
        </div>
      </template>
      <template #cell(roles)="data" v-if="roles">
        {{ getRoleName(data.item) }}
      </template>
    </b-table>
    <PaginationRouter v-if="table.pagination.totalPages > 1" :pagination="table.pagination" :linkGen="paginationRouter"/>
  </div>
  <b-spinner v-else label="Loading..." small></b-spinner>
</template>

<script>
import PaginationRouter from '@/common/components/Pagination.Router';
import {BIconPencil} from 'bootstrap-vue';
export default {
  props: ['table','paginationRouter'],
  components: {
    BIconPencil,
    PaginationRouter
  },

  data(){
    return {
      roles: []
    }
  },

  methods: {
    getName(item){
      let arr = [];
      if(item.firstName){
        arr.push(item.firstName);
      }
      if(item.lastName){
        arr.push(item.lastName);
      }

      if(arr.length === 0){
        return null;
      }

      return arr.join(' ');
    },

    getRoleName(item){
      let arr = item.roles.map(item => {
        let role = this.roles.find(o => o.id === item.id);
        return role ? role.name : "";
      });
      return arr.join(" ");
    },

    getRoles(){
      this.$api.get('roles').then(({data}) => {
        this.roles = data.records;
      })
    }
  },

  mounted(){
    this.getRoles();
  }
};
</script>
