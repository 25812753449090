<template>
    <FormTemplate v-if="form" :form="form">
        <div class="row">
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputFirstName">First Name</label>
                    <b-form-input
                        type="text" id="inputFirstName" class="form-control form-control-xl"
                        placeholder="enter first name"
                        v-model="form.data.firstName"
                        :state="form.states.firstName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.firstName }}</b-form-invalid-feedback>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="form-group">
                    <label class="label-for-xl" for="inputLastName">Last Name</label>
                    <b-form-input
                        type="text" id="inputLastName" class="form-control form-control-xl"
                        placeholder="enter last name"
                        v-model="form.data.lastName"
                        :state="form.states.lastName"
                    />
                    <b-form-invalid-feedback v-if="form.errors">{{ form.errors.lastName }}</b-form-invalid-feedback>
                </div>
            </div>
        </div>
        <b-form-group class="label-for-xl" label="E-Mail Address" label-for="inputEmail">
            <b-form-input
                type="text" id="inputEmail" class="form-control form-control-xl"
                placeholder="enter the email address"
                v-model="form.data.email"
                :state="form.states.email"
            />
            <b-form-invalid-feedback v-if="form.errors">{{ form.errors.email }}</b-form-invalid-feedback>
        </b-form-group>
    </FormTemplate>
</template>

<script>
import {Form} from '@/common/utils/parnekt';
import FormTemplate from '@/common/components/Form/Form';

export default {
    props: ['userDetails'],
    components: {
        FormTemplate
    },
    data(){
        return {
            form: null
        };
    },

    methods: {

    },

    mounted(){
      let fields = {firstName: "",lastName: "",email: ""};
      if(this.userDetails){
        fields = {
          firstName: this.userDetails.firstName || "",
          lastName: this.userDetails.lastName || "",
          email: this.userDetails.email || ""
        };
      }
      this.form = new Form(fields,{
        onSubmit: (form) => {
          let url = 'user';
          let method = 'post';
          let emitEvent = 'user-created';
          if(this.userDetails){
            url = `user/${this.userDetails.id}/account`;
            method = "put";
            emitEvent = 'user-updated';
          }

          return this.$api[method](url,{
            data: {
              firstName: form.get('firstName'),
              lastName: form.get('lastName'),
              email: form.get('email')
            }
          }).then((res) => {
            this.$emit(emitEvent,res.data);
            return res;
          });
        }
      });
    }
};
</script>
