import moment from 'moment';

function formatDate(datetime,format){
    return moment(datetime).format(format);
}

function randomInt(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

function randomString(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
         result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function uniqueArray(arr) {
    var a = arr.concat();
    for(var i=0; i<a.length; ++i) {
        for(var j=i+1; j<a.length; ++j) {
            if(a[i] === a[j])
                a.splice(j--, 1);
        }
    }

    return a;
}

export{
    formatDate,
    randomInt,
    randomString,
    uniqueArray
}
