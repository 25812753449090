<template>
    <div class="permission-filters">
      <b-row>
        <b-col>
          <b-form-input @keyup="onTextChange()" v-model="filters.query" type="text" placeholder="find..."></b-form-input>
        </b-col>
      </b-row>
      <b-button v-if="hasFilters" variant="primary" size="sm" class="mt-3" @click="clearFilters()">Clear Filters</b-button>
    </div>
</template>

<script>
export default {
    components: {
    },
    data(){
        return {
            filters: {
              query: null,
            },
            searchTimer: null,
        };
    },

    methods: {
      onTextChange(){
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }
        this.searchTimer = setTimeout(() => {
          this.emitSearch();
        }, 500);
      },

      onChange(){
        this.emitSearch();
      },

      emitSearch(){
        let filters = {
          query: (this.filters.query === "" ? null : this.filters.query),
        };
        this.$emit("on-search", filters);
      },

      clearFilters(){
        this.filters.query = null;
        this.emitSearch();
      },

      countFilters(){
        let cnt = 0;
        if(this.filters.query){
          cnt++;
        }

        return cnt;
      }
    },

    mounted(){

    },

    computed: {
      hasFilters(){
        return this.countFilters() > 0 ? true : false;
      }
    }
};
</script>
