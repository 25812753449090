<template>
  <div></div>
</template>

<script>
export default {
    props: ['userDetails'],
    components: {

    },
    data(){
        return {

        };
    },

    methods: {

    },

    mounted(){

    }
};
</script>
