<template>
  <div v-if="!loading.all" class="user-rp">
    <b-alert
      v-if="success.messages.length > 0"
      :show="dismissCountDown"
      dismissible
      variant="success"
      @dismissed="dismissCountDown=0"
      @dismiss-count-down="countDownChanged"
    >
      <div v-for="(msg,key) in success.messages" :key="key">{{msg}}</div>
    </b-alert>

    <b-form-group class="label-for-xl font-weight-bold mb-3 pb-3" label="Role" label-for="inputRole">
      <b-form-select v-model="roles.selected" :options="roles.options" @change="onRoleChange()"></b-form-select>
    </b-form-group>

    <div class="font-weight-bold mt-3 pt-3 pb-3 border-bottom">Permissions</div>

    <div style="height: 400px; overflow-y: auto;">
      <b-form-group v-if="!loading.permissions" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="permissions.selected"
          :aria-describedby="ariaDescribedby"
          name="user-permissions"
        >
        <div v-for="p in permissions.parsed" :key="p.group" class="mt-3 mb-3">
          <div class="text-uppercase">{{ p.group }}</div>
          <ul class="user-permissions-group">
            <li v-for="item in p.items" :key="item.id">
              <b-form-checkbox :value="item.placeholder">
                {{ item.name }}
              </b-form-checkbox>
            </li>
          </ul>
        </div>
        </b-form-checkbox-group>
      </b-form-group>
      <b-spinner v-else label="Loading..." small class="mt-3"></b-spinner>
    </div>

    <b-button :disabled="loading.saving" class="mt-3" variant="primary" size="sm" @click="save()">Update</b-button>
  </div>
  <b-spinner v-else label="Loading..." small></b-spinner>
</template>

<script>
import {uniqueArray} from '@/common/utils/common';
export default {
    props: ['userDetails'],
    components: {

    },
    data(){
        return {
          success: {
            messages: []
          },
          dismissSecs: 5,
          dismissCountDown: 0,
          showDismissibleAlert: false,
          loading: {
            all: false,
            permissions: false,
            saving: false
          },
          role: null,
          roles: {
            selected: null,
            options: [],
            all: []
          },
          permissions: {
            selected: [],
            options: [],
            parsed: []
          },
        };
    },

    methods: {
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
        if(dismissCountDown === 0){
          this.success.messages = [];
        }
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      onRoleChange(){
        this.loading.permissions = true;
        let role = this.roles.all.find(o => o.placeholder === this.roles.selected);
        this.setRole(role.id).then(() => {
          this.setRolePermissions(true);
        });
      },

      setRole(roleId){
        return this.$api.get(`role/${roleId}`).then(({data}) => {
          this.role = data;
        })
      },

      setRolePermissions(mergePermissions){
        let rolePermissions = this.role.permissions.map(item => item.ph);
        this.permissions.selected = mergePermissions ? uniqueArray(rolePermissions.concat(this.userDetails.permissions)) : rolePermissions;
        this.loading.permissions = false;
      },

      getRoles(){
        return this.$api.get('roles').then(({data}) => {
          this.roles.all = data.records;
          this.roles.options = data.records.map(item => {
            return {
              value: item.placeholder,
              text: item.name,
            }
          })

          this.roles.selected = this.userDetails.roles[0].ph;
        });
      },

      getPermissions(){
        return this.$api.get('permissions',{
          params: {
            perPage: 1000,
            orderBy: "placeholder",
            arrange: "asc"
          }
        }).then(({data}) => {
          this.permissions.options = data.records;
          this.parsePermissions(data.records);
        });
      },

      parsePermissions(records){
        for(let i in records){
          let item = records[i];
          let m = item.placeholder.match(/^(.*):(.*)$/);
          let group, name = null;
          if(m){
            group = m[1];
            name = m[2];
          }
          else{
            group = 'ungroupped';
            name = item.placeholder;
          }
          let pGroup = this.permissions.parsed.find(o => o.group === group);
          if(pGroup){
            pGroup.items.push({
              id: item.id,
              name: name,
              placeholder: item.placeholder
            })
          }
          else{
            this.permissions.parsed.push({
              group: group,
              items: [
                {
                  id: item.id,
                  name: name,
                  placeholder: item.placeholder
                }
              ]
            });
          }
        }
      },

      isCustomPermission(item){
        let isSelected = this.permissions.selected.indexOf(item.placeholder) !== -1;
        return this.userDetails.permissions.indexOf(item.placeholder) !== -1 ? false : (isSelected ? true : false);
      },

      save(){
        this.loading.saving = true;
        let data = {
          role: {role: this.role.id},
          permissions: {
            permissions: this.permissions.options.filter(o => this.permissions.selected.includes(o.placeholder)).map(item => item.id)
          }
        }

        if(this.userDetails.roles[0].id !== this.role.id){
          this.$api.put(`user/${this.userDetails.id}/role`,{data: data.role}).then(roleRes => {
            this.success.messages.push(roleRes.message);
            return this.$api.put(`user/${this.userDetails.id}/permissions`,{data: data.permissions}).then(permissionRes => {
              this.success.messages.push(permissionRes.message);
              this.showAlert();
            })
          })
          .catch(e => {
            this.loading.saving = false;
            console.log("cannot save role and permissions", e);
          })
        }
        else{
          this.$api.put(`user/${this.userDetails.id}/permissions`,{data: data.permissions}).then(permissionRes => {
            this.success.messages.push(permissionRes.message);
            this.showAlert();
          })
          .catch(e => {
            this.loading.saving = false;
            console.log("cannot save role and permissions", e);
          })
        }
      }
    },

    mounted(){
      this.loading.all = true;
      Promise.all([
        this.getRoles(),
        this.getPermissions(),
        this.setRole(this.userDetails.roles[0].id)
      ]).then(() => {
        this.permissions.selected = this.userDetails.permissions;
        this.loading.all = false;
      })
    }
};
</script>
