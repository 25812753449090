<template>
    <div class="user-filters">
      <b-row>
        <b-col col lg="4" md="6" sm="12" class="mb-3">
          <b-form-input @keyup="onTextChange()" v-model="filters.query" type="text" placeholder="find..."></b-form-input>
        </b-col>
        <b-col col lg="4" md="6" sm="12" class="mb-3">
          <b-form-select @change="onChange('roles')" v-model="filters.roles.selected" :options="filters.roles.options"></b-form-select>
        </b-col>
        <b-col col lg="4" md="6" sm="12" class="mb-3">
          <b-form-select @change="onChange('statuses')" v-model="filters.statuses.selected" :options="filters.statuses.options"></b-form-select>
        </b-col>
      </b-row>

      <b-row v-if="showParentsFilters === 'yes'">
        <b-col col lg="4" md="6" sm="12">
          <b-form-select @change="onChange('parentsOnly')" v-model="filters.parentsOnly.selected" :options="filters.parentsOnly.options"></b-form-select>
        </b-col>
      </b-row>

      <b-button v-if="hasFilters" variant="primary" size="sm" class="mt-3" @click="clearFilters()">Clear Filters</b-button>
    </div>
</template>

<script>
export default {
    props: ['showParentsFilters'],
    components: {
    },
    data(){
        return {
            filters: {
              query: null,
              roles: {
                selected: null,
                options: []
              },
              statuses: {
                selected: null,
                options: []
              },
              parentsOnly: {
                selected: null,
                options: []
              }
            },
            searchTimer: null,
        };
    },

    methods: {
      onTextChange(){
        if (this.searchTimer) {
          clearTimeout(this.searchTimer);
          this.searchTimer = null;
        }
        this.searchTimer = setTimeout(() => {
          this.emitSearch();
        }, 500);
      },

      onChange(){
        this.emitSearch();
      },

      emitSearch(){
        let filters = {
          query: (this.filters.query === "" ? null : this.filters.query),
          role: this.filters.roles.selected,
          status: this.filters.statuses.selected,
        };
        if(this.showParentsFilters === "yes"){
          filters.parentsOnly = this.filters.parentsOnly.selected
        }
        this.$emit("on-search", filters);
      },

      clearFilters(){
        this.filters.query = null;
        this.filters.roles.selected = null;
        this.filters.statuses.selected = null;
        if(this.showParentsFilters === "yes"){
          this.filters.parentsOnly.selected = "y";
        }
        this.emitSearch();
      },

      countFilters(){
        let cnt = 0;
        if(this.filters.query){
          cnt++;
        }

        if(this.filters.roles.selected){
          cnt++;
        }

        if(this.filters.statuses.selected){
          cnt++;
        }

        if(this.showParentsFilters === "yes" && this.filters.parentsOnly.selected === "n"){
          cnt++;
        }

        return cnt;
      }
    },

    mounted(){
      this.filters.statuses.options = [
        {
          text: "--- choose status ---",
          value: null
        },
        {
          text: "Pending",
          value: "pending"
        },
        {
          text: "Active",
          value: "active"
        },
        {
          text: "Closed",
          value: "closed"
        },
        {
          text: "Suspended",
          value: "suspended"
        },
      ];

      if(this.showParentsFilters === "yes"){
        this.filters.parentsOnly.options = [
          {
            text: "Show top accounts",
            value: "y"
          },
          {
            text: "Show all accounts",
            value: "n"
          },
        ];
        this.filters.parentsOnly.selected = "y";
      }


      this.$api.get('roles').then(({data}) => {
        this.filters.roles.options = [
          {
            text: "--- choose role ---",
            value: null
          }
        ].concat(data.records.map(item => {
          return {
            value: item.id,
            text: item.name
          }
        }));
      })
    },

    computed: {
      hasFilters(){
        return this.countFilters() > 0 ? true : false;
      }
    }
};
</script>
